import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class HeaderStateService {

    constructor(private router: Router) { }

    public isDefaultHeaderDisplayed = new BehaviorSubject<boolean>(false);
    public isDetailHeaderDisplayed = new BehaviorSubject<boolean>(false);

    public pageTitle = new BehaviorSubject<string>('');
    public pageSubtitle = new BehaviorSubject<string>('');

    public actionIcon = new BehaviorSubject<string>('');
    public returnUrl?: string;

    private actionClicked$ = new EventEmitter;

    public setDetailHeader(title?: string, subtitle?: string, returnUrl?: string, actionIcon?: string) {
        this.isDefaultHeaderDisplayed.next(false);
        this.isDetailHeaderDisplayed.next(true);

        this.pageTitle.next(title ?? '');
        this.pageSubtitle.next(subtitle ?? '');
        this.actionIcon.next(actionIcon ?? '');

        this.returnUrl = returnUrl;
    }

    public showDefaultHeader() {
        this.isDefaultHeaderDisplayed.next(true);
        this.isDetailHeaderDisplayed.next(false);
    }

    public hideHeader() {
        this.isDefaultHeaderDisplayed.next(false);
        this.isDetailHeaderDisplayed.next(false);
    }

    public navigateBack() {
        this.router.navigateByUrl(this.returnUrl!);
    }

    public getActionClickedEvent(): EventEmitter<any> {
        this.actionClicked$ = new EventEmitter;
        return this.actionClicked$;
    }

    public triggerActionClickedEvent() {
        this.actionClicked$.next(null);
    }

}